import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";

import Loader from "./components/Loader";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
const root = ReactDOM.createRoot(document.getElementById("root"));
const App = React.lazy(() => import("./App"));
root.render(
  <GoogleOAuthProvider clientId="573888473284-r9nkqs2n7f14v33fq7g50dtm18m4qdqf.apps.googleusercontent.com">
    <React.StrictMode>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          {/* Loader will be displayed while loading */}
          <App />
        </Suspense>
      </BrowserRouter>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
